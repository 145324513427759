/* Dashboard.css */
* {
    margin: 0px;
    padding: 0px;
}

.insideDashboard {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.navigationbar {
    width: 20%;
    padding: 20px;
    background-color: #2e3345;
    color: #fff;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    /* border:1px solid red; */
}


.navigationbar ul {
    list-style: none;
    padding: 0;
}

.navigationbar button {
    margin: 0;
    padding: 5px 0;
    cursor: pointer;
    transition: background-color 0.2s;
    cursor: pointer;
    text-align: left;
    background-color: #2e3345;
    border: none;
    color: white;
    /* font-size: 1.2rem; */
}

.navigationbar button:hover {
    background-color: #555;
    color: #007bff;
    border-radius: 5px;
}

.layout {
    flex: 1;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* box-shadow: 0 2px 5px rgba(242, 83, 9, 1); */
    width: 100%;
}

/* Style the main content area text */
.layout p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

/* Style the "Welcome to the Main Content Area" heading */
.layout h1 {
    font-size: 24px;
    color: #222;
}

/* Style the navigation list */
.navigationbar ul {
    padding: 0;
    list-style-type: none;
}

/* Style the navigation list items */
.navigationbar ul li {
    margin-bottom: 10px;
}

/* Add hover effect for list items */
.navigationbar ul li:hover {
    /* background-color: #555; */
    border-radius: 5px;
    cursor: pointer;
}



/* Add spacing between list items */
.navigationbar ul li:not(:last-child) {
    margin-bottom: 10px;
}

.layout h1 {
    font-size: 24px;
    color: #222;
    margin-bottom: 20px;
}




.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* Optional: Set the height of the entire dashboard */
}

.insideDashboard {
    flex: 1;
    overflow-y: auto;
}

.navigationbar, .layout {
    overflow-y: auto;
    overflow-x: auto;
}

.navHandling {
    margin: 1px;
    padding: 0.5rem 0.2rem;
    display: flex;
    justify-content: space-between;

}

.profileDetail {
    /* border:1px solid white; */

}

.profileDetail p {
    margin: 0px !important;
    padding-left: 0.5rem;
}

.profileDetail .profileName {
    /* border:1px solid red; */
    font-size: 1.5rem;
    color: #ff5722;
}

.profileDetail .profileId {
    /* border:1px solid red; */
    font-size: 1rem;
}

.navHandling * {
    border: 1px solid red;
}

.navHandling button {
    border: 1px solid white;
    margin: 0rem 0rem !important;
    padding: 0rem 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    height: 2rem;

}

.navHandling .profile {
    /* border:1px solid red; */
    margin: 0px !important;
    padding: 0px !important;
}





.navItems {
    display: flex;
    flex-direction: column;
}

.navItems button {
    border: 1px solid rgb(90, 88, 88);
    padding-left: 0.5rem;

}

.sideButton {
    padding: 10px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.dashboard {
    position: relative;
}

.dashboard .growSpinnerHead {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.navbarSection {
    display: flex;
    flex-direction: row;
    padding: 0.1rem 0rem;
    background-color: #2e3345;
    height: 2rem;
    /* border:1px solid red; */
}

.navbarSection .toggleButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.2rem;
    cursor: pointer;
}

.navbarSection .line {
    width: 30px;
    height: 4px;
    margin: 2px 0px;
    font-weight: bold;
    background-color: white;
    border-radius: 10px;
}

.navbarSection .navSection2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border:1px solid red; */
}

.navbarSection .navSection2 .home {
    margin: 0px;
    padding: 0px 0.5rem;
    /* border: 1px solid black; */
    font-weight: bold;
    color: white;
    /* border: 1px solid red; */
}

.navbarSection .navSection2 .navRight {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    height: 100%;
    /* border:1px solid red; */
}

.navbarSection .navSection2 img {
    width: 100%;
    height: 100%;
    margin: 0rem 0.2rem;
}

.navbarSection .navSection2 .noti {
    padding: 0.3rem;
}


.navigationbar .navCompanyName {
    margin: 0px;
    /* padding:0.5rem 0rem; */
    color: #2e95f7;
    font-weight: bold;
    letter-spacing: 1px;
    /* padding-left: 0.5rem; */
    text-align: center;
}

.navigationbar .comLastName {
    color: #007bff;
    text-align: center;
    font-size: 0.7rem;
    ;
    /* border:1px solid red; */
    margin: 0px;
    margin-bottom: 0.5rem;
}


.custom-toggle {
    background-color: transparent;
    box-shadow: none !important;
    margin: 0px;
    padding: 0px;
    border: none;
    width: 1.6rem;
    height: 1.6rem;

}

.custom-toggle::after {
    display: none !important;
}

.custom-toggle:hover,
.custom-toggle:active {
    background-color: transparent !important;
}


.custom-toogle-body * {
    margin: 0px;
    padding: 0px;
}

.custom-toogle-body {
    /* border:1px solid red;  */
    padding: 0.5rem;
    width: 25vw;
    background-color: #f5f7f8;
    color: #707b83;
}

.custom-toogle-body button {
    background-color: rgb(66,
            135,
            245);
    border-radius: 5px;
    width: 100%;
    color: white;
    font-weight: bold;
    border: none;
}

.custom-toogle-body button:hover {
    background-color: #ff5722;
}

.custom-toogle-body-img {
    /* background-color: red; */
    width: 1.6rem;
    height: 1.6rem;
    /* color: red; */
    display: flex;
    flex-direction: row;
}

.custom-toogle-body-img img {
    background-color: #bdc3c6;
    border-radius: 15px;
}

.control-login {
    /* border:1px solid red; */
    /* padding:1rem; */
}

.control-login .loggP {
    padding: 0.5rem 0rem;
}

.logoutButton {
    margin: 0.5rem 0rem;
}




.insideDashboard button:active {
    border: 1px solid orangered;
}

.shopStatusIcon {
    font-size: 1.5rem;
}