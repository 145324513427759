* {
    margin: 0px;
    padding: 0px;
}

.veriHead {
    /* border: 1px solid red; */
    position: relative;
    font-family: 'Poppins', sans-serif;
}


.processingHeadVerify {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.veriHeadInside {
    /* border: 1px solid green; */
}

.veriTilte {
    font-weight: bold;
    text-align: center;
    margin: 1rem 0rem;
}



.veriPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sIdInputField {
    outline: none;
    color: black;
    padding: 0.5rem;
    text-align: center;
    /* background-color: rgb(253, 253, 190); */
    border-radius: 10px;
    font-weight: bold;
    margin: 0rem 1rem;
    padding: 10px;
    border: 2px solid #ccc;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.checkUserBtn {
    border: none;
    background-color: green;
    width: 50%;
    color: white;
    text-align: center;
    padding: 0.3rem;
    margin: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 10px;
}


.showResultsContainer {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
}

.showResultsContainer .item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0.5rem 1rem;

}

.showResultsContainer .item label {
    /* border: 1px solid red; */
    font-weight: bold;
    font-size: 1rem;
}

.showResultsContainer .item p {
    /* border: 1px solid green; */
    margin-bottom: 0px;
    font-size: 1rem;
    padding: 0rem 0.1rem;
}

.message{
    color: rgb(54, 225, 54);
}