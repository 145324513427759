* {
    margin: 0px;
    padding: 0px;
}

.navbarTop {
    
    font-family: 'Playfair Display', serif;
    width: 100%;
    padding: 0.2rem;
}

.navbarHead {
    padding: 0px;
}



.logoImg {
    margin: 0rem 0.3rem;
    width: 100%;
    height: 2.5rem;
}

.navHeading {
    font-weight: bold;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2e95f7;
    /* font-family: 'Comic Sans MS', cursive; */
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-size: 1rem;

}

.collapseItem {
    color: #2e95f7;
}



.toggleBtn {
    box-shadow: none !important;
    border: none;
}




.loginHead .item-container {
    display: flex;
    flex-direction: row;


}

.crossBtnHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid red; */
    margin: 0px;
}

.sec1 {
    width: 100%;

    text-align: center;
}

.crossBtn {
    display: inline;
    background-color: #1b2a37;
}


.loginHead .item-container .item {
    display: flex;
    flex-direction: column;
    margin: 1rem 0px;
}

.loginHead .item-container .item input {
    width: 100%;
    margin: 0.3rem 0rem;
    /* border: 2px solid rgb(135, 134, 134); */
    padding: 0.3rem;
    border-radius: 15px;
    outline: none;
    font-weight: bold;
}

.loginHead .item-container .item label {
    font-size: 1rem;
    margin: 0.3rem 0rem;
    padding: 0.3rem;
    padding-right: 0.8rem;
}

.loginBtnHead {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginBtn {
    border: none;
    padding: 0.1rem 0.5rem;
    border-radius: 20px;
    background-color: #2e95f7;
    color: white;
    font-weight: bold;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.6);
    width: 100%;
    margin: 0px 2rem;
}

.loginBtn :hover {
    background-color: green;
}

.canFor {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1rem 0rem;
}

.canFor button {
    background-color: white;
    border: none;
}

.canFor .cancel {
    text-decoration: underline;
    color: #2e95f7;
}

.canFor .forgot {
    text-decoration: underline;
    color: #2e95f7;
}




@media (max-width: 422px) {
    .logoImg {
        display: none;
    }

    .navbarHeading span {
        padding-left: 0.5rem;
    }
}

@media (max-width: 370px) {
    .navbarHeading span {
        font-size: 1rem;
    }
}

@media (max-width: 320px) {
    .navbarHeading span {
        font-size: 0.8rem;
    }
}

@media (max-width: 280px) {
    .navbarHeading span {
        font-size: 0.5rem;
    }
}